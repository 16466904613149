import React, { useState } from "react"
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import PaitentVitalTable from "./KeyParametersTable"

const tabData = [
  { name: "Vision Health", component: <PaitentVitalTable /> },
  { name: "BMI", component: <PaitentVitalTable /> },
  { name: "Blood Temperature", component: <PaitentVitalTable /> },
  { name: "Blood Pressure", component: <PaitentVitalTable /> },
  { name: "Oxygen Saturation", component: <PaitentVitalTable /> },
  { name: "Heart / Pulse Rate", component: <PaitentVitalTable /> },
  { name: "Respiratory Rate", component: <PaitentVitalTable /> },
  { name: "Spirometer Test", component: <PaitentVitalTable /> },
  {
    name: "Peripheral Neuropathy Detection test",
    component: <PaitentVitalTable />,
  },
]

function KeyVitalsCollapseTab() {
  const [activeTab, setActiveTab] = useState(tabData[0].name) // Default to the first tab
  const [openSections, setOpenSections] = useState(
    tabData.reduce(
      (acc, tab) => {
        acc[tab.name] = false
        return acc
      },
      { allOpen: false }
    )
  )

  const toggleSection = section => {
    setOpenSections(prevState => ({
      ...prevState,
      [section]: !prevState[section],
      allOpen: false,
    }))
  }

  const toggleAllSections = () => {
    const nextAllOpenState = !openSections.allOpen
    const updatedSections = tabData.reduce((acc, tab) => {
      acc[tab.name] = nextAllOpenState
      return acc
    }, {})
    setOpenSections({ ...updatedSections, allOpen: nextAllOpenState })
  }

  const renderTabContent = tab => (
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item mx-2">
        <h2 className="accordion-header " id={`headingFlush${tab.name}`}>
          <button
            className={classnames("accordion-button", "fw-medium", {
              collapsed: !openSections[tab.name],
            })}
            type="button"
            onClick={() => toggleSection(tab.name)}
            style={{ cursor: "pointer" }}
          >
            {tab.name}
          </button>
        </h2>
        <Collapse
          isOpen={openSections[tab.name]}
          className="accordion-collapse"
        >
          <div className="accordion-body">{tab.component}</div>
        </Collapse>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Card style={{ border: "2px solid #e5e8eb", borderRadius: 7 }}>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    className="bg-primary m-2"
                    onClick={toggleAllSections}
                  >
                    {openSections.allOpen ? "Close All" : "Open All"}
                  </Button>
                </div>
                {tabData.map((tab, index) => (
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                    key={index}
                  >
                    <div className="accordion-item mx-2">
                      <h2
                        className="accordion-header "
                        id={`headingFlush${tab.name}`}
                      >
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !openSections[tab.name],
                            }
                          )}
                          type="button"
                          onClick={() => toggleSection(tab.name)}
                          style={{ cursor: "pointer" }}
                        >
                          {tab.name}
                        </button>
                      </h2>
                      <Collapse
                        isOpen={openSections[tab.name]}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">{tab.component}</div>
                      </Collapse>
                    </div>
                  </div>
                ))}

                {/* <Nav tabs className="mb-4">
                  {tabData.map((tab, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === tab.name,
                        })}
                        onClick={() => setActiveTab(tab.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {tab.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {tabData.map((tab, index) => (
                    <TabPane tabId={tab.name} key={index}>
                      {renderTabContent(tab)}
                    </TabPane>
                  ))}
                </TabContent> */}
              </Col>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default KeyVitalsCollapseTab

// import React, { useState } from "react"
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Collapse,
//   Container,
//   Row,
// } from "reactstrap"

// import classnames from "classnames"
// import ViraltrendComp1 from "./comp/ViraltrendComp1"
// import NewbornCaseRecordView from "./NewbornCaseRecordView"
// import SystematicExaminationView from "./SystematicExaminationView"

// function KeyVitalsCollapseTab() {
//   const [col9, setcol9] = useState(false)
//   const [col10, setcol10] = useState(false)
//   const [col11, setcol11] = useState(false)
//   const [allOpen, setAllOpen] = useState(false)

//   const toggleSection = section => {
//     if (section === "col9") {
//       setcol9(!col9)
//       if (!col9) setAllOpen(false)
//     } else if (section === "col10") {
//       setcol10(!col10)
//       if (!col10) setAllOpen(false)
//     } else if (section === "col11") {
//       setcol11(!col11)
//       if (!col11) setAllOpen(false)
//     }
//   }

//   const toggleAllSections = () => {
//     const nextAllOpenState = !allOpen
//     setcol9(nextAllOpenState)
//     setcol10(nextAllOpenState)
//     setcol11(nextAllOpenState)
//     setAllOpen(nextAllOpenState)
//   }

//   const formGroupStyle = {
//     border: "2px solid  #e5e8eb",
//     borderRadius: 7,
//   }

//   return (
//     <React.Fragment>
//       <Container fluid={true}>
//         <Row>
//           <Card style={formGroupStyle}>
//             <>
//               <Row>
//                 <Col>
//                   <div>
//                     <div className=" text-end">
//                       <Button
//                         className="bg-primary m-2 "
//                         onClick={toggleAllSections}
//                       >
//                         {allOpen ? "Close All" : "Open All"}
//                       </Button>
//                     </div>
//                     <div
//                       className="accordion accordion-flush"
//                       id="accordionFlushExample"
//                     >
//                       <div className="accordion-item">
//                         <h2 className="accordion-header" id="headingFlushOne">
//                           <button
//                             className={classnames(
//                               "accordion-button",
//                               "fw-medium",
//                               { collapsed: !col9 }
//                             )}
//                             type="button"
//                             onClick={() => toggleSection("col9")}
//                             style={{ cursor: "pointer" }}
//                           >
//                             Viral Trend
//                           </button>
//                         </h2>

//                         <Collapse isOpen={col9} className="accordion-collapse">
//                           <div className="accordion-body1">
//                             <ViraltrendComp1 />
//                           </div>
//                         </Collapse>
//                       </div>
//                       <div className="accordion-item">
//                         <h2 className="accordion-header" id="headingFlushTwo">
//                           <button
//                             className={classnames(
//                               "accordion-button",
//                               "fw-medium",
//                               { collapsed: !col10 }
//                             )}
//                             type="button"
//                             onClick={() => toggleSection("col10")}
//                             style={{ cursor: "pointer" }}
//                           >
//                             24 May 2024 5:00 PM Systemic Examination
//                           </button>
//                         </h2>

//                         <Collapse isOpen={col10} className="accordion-collapse">
//                           <div className="accordion-body">
//                             <SystematicExaminationView />
//                             {/* <div className="text-muted">
//                               Lorem Ipsum It is hidden by default, until the
//                               collapse plugin adds the appropriate classes that
//                               we use to style
//                             </div> */}
//                           </div>
//                         </Collapse>
//                       </div>
//                       <div className="accordion-item">
//                         <h2 className="accordion-header" id="headingFlushThree">
//                           <button
//                             className={classnames(
//                               "accordion-button",
//                               "fw-medium",
//                               { collapsed: !col11 }
//                             )}
//                             type="button"
//                             onClick={() => toggleSection("col11")}
//                             style={{ cursor: "pointer" }}
//                           >
//                             24 May 2024 5:00 PM General Examination
//                           </button>
//                         </h2>
//                         <Collapse isOpen={col11} className="accordion-collapse">
//                           <div className="accordion-body">
//                             <NewbornCaseRecordView />
//                           </div>
//                         </Collapse>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </>
//           </Card>
//         </Row>
//       </Container>
//     </React.Fragment>
//   )
// }

// export default KeyVitalsCollapseTab
