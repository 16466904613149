import React, { useState } from "react"
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import PaitentVitalTable from "./KeyParametersTable"

const tabData = [
  { name: "ECG", component: <PaitentVitalTable /> },
  { name: "Echo", component: <PaitentVitalTable /> },
  { name: "X-ray Chest", component: <PaitentVitalTable /> },
  { name: "Ultrasound", component: <PaitentVitalTable /> },
  { name: "Bone Density Test", component: <PaitentVitalTable /> },
  { name: "Mammography", component: <PaitentVitalTable /> },
  { name: "CT Scan", component: <PaitentVitalTable /> },
  { name: "MRI", component: <PaitentVitalTable /> },
]

function RadiologyCollapseTab() {
  const [activeTab, setActiveTab] = useState(tabData[0].name) // Default to the first tab
  const [openSections, setOpenSections] = useState(
    tabData.reduce(
      (acc, tab) => {
        acc[tab.name] = false
        return acc
      },
      { allOpen: false }
    )
  )

  const toggleSection = section => {
    setOpenSections(prevState => ({
      ...prevState,
      [section]: !prevState[section],
      allOpen: false,
    }))
  }

  const toggleAllSections = () => {
    const nextAllOpenState = !openSections.allOpen
    const updatedSections = tabData.reduce((acc, tab) => {
      acc[tab.name] = nextAllOpenState
      return acc
    }, {})
    setOpenSections({ ...updatedSections, allOpen: nextAllOpenState })
  }

  const renderTabContent = tab => (
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item mx-2">
        <h2 className="accordion-header " id={`headingFlush${tab.name}`}>
          <button
            className={classnames("accordion-button", "fw-medium", {
              collapsed: !openSections[tab.name],
            })}
            type="button"
            onClick={() => toggleSection(tab.name)}
            style={{ cursor: "pointer" }}
          >
            {tab.name}
          </button>
        </h2>
        <Collapse
          isOpen={openSections[tab.name]}
          className="accordion-collapse"
        >
          <div className="accordion-body">{tab.component}</div>
        </Collapse>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Card style={{ border: "2px solid #e5e8eb", borderRadius: 7 }}>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    className="bg-primary m-2"
                    onClick={toggleAllSections}
                  >
                    {openSections.allOpen ? "Close All" : "Open All"}
                  </Button>
                </div>
                {tabData.map((tab, index) => (
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                    key={index}
                  >
                    <div className="accordion-item mx-2">
                      <h2
                        className="accordion-header "
                        id={`headingFlush${tab.name}`}
                      >
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !openSections[tab.name],
                            }
                          )}
                          type="button"
                          onClick={() => toggleSection(tab.name)}
                          style={{ cursor: "pointer" }}
                        >
                          {tab.name}
                        </button>
                      </h2>
                      <Collapse
                        isOpen={openSections[tab.name]}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">{tab.component}</div>
                      </Collapse>
                    </div>
                  </div>
                ))}

                {/* <Nav tabs className="mb-4">
                  {tabData.map((tab, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === tab.name,
                        })}
                        onClick={() => setActiveTab(tab.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {tab.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {tabData.map((tab, index) => (
                    <TabPane tabId={tab.name} key={index}>
                      {renderTabContent(tab)}
                    </TabPane>
                  ))}
                </TabContent> */}
              </Col>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default RadiologyCollapseTab
