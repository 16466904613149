import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
// import VideoCall from "./VideoCall"
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
import { Card } from "reactstrap"
const FullScreenCall = () => {
  // Parse URL parameters
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const roomID = params.get("roomID")
  const userID = params.get("userID")
  const userName = params.get("userName")

  const APP_ID = 1233739532 // Your ZEGOCLOUD appID
  const SERVER_SECRET = "bd4fc7c7e896e364e33a6395cb09d6ed" // Your server secret

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          style={
            {
              // width: "100vw",
              // height: "100vh",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // backgroundColor: "#f0f0f0",
            }
          }
        >
          <div
          //    style={{ width: "100%", maxWidth: "1200px", height: "100%" }}
          >
            <VideoCall
              roomID={roomID}
              userID={userID}
              userName={userName}
              appID={APP_ID}
              serverSecret={SERVER_SECRET}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const VideoCall = ({ roomID, userID, userName, appID, serverSecret }) => {
  // Function to detect mobile devices
  function isMobileDevice() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  const initializeCall = async () => {
    const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      userID,
      userName
      //   userID.toString(), // Ensure userID is a string
      // userName.toString() // Ensure userName is a string
    )
    // Detect whether the device is mobile
    const showCameraToggle = isMobileDevice()
    const zp = ZegoUIKitPrebuilt.create(token)

    zp.joinRoom({
      container: document.getElementById("zego-container"),
      sharedLinks: [
        {
          name: "Join Call",
          // url: `${window.location.origin}${window.location.pathname}?roomID=${roomID}`,
          url: `${window.location.origin}/VideoCalling?roomID=${roomID}`,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference, // For 1:1 calls
        // mode: ZegoUIKitPrebuilt.OneONoneCall, // For 1:1 calls
        // mode: ZegoUIKitPrebuilt.VideoResolution_720P, // For 1:1 calls
      },
      // showScreenSharingButton: false,
      // showMyCameraToggleButton: true,

      // videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P, // Set HD resolution
      videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P, // Set video resolution to 1080P
      turnOnMicrophoneWhenJoining: true, // Turn on the microphone by default
      turnOnCameraWhenJoining: true, // Turn on the camera by default
      useFrontFacingCamera: true, // Use the front-facing camera
      showScreenSharingButton: false, // Optional: enable screen sharing
      showMyCameraToggleButton: true, // Allow toggling the camera
      // Detect whether the device is mobile
      // showMyCameraToggleButton: showCameraToggle, // Show camera toggle only on mobile devices
      showMyMicrophoneToggleButton: true, // Allow toggling the microphone
      useFrontFacingCamera: true,
    })

    // {
    //            	turnOnMicrophoneWhenJoining: true,
    //            	turnOnCameraWhenJoining: true,
    //            	showMyCameraToggleButton: true,
    //            	showMyMicrophoneToggleButton: true,
    //            	showAudioVideoSettingsButton: true,
    //            	showScreenSharingButton: false,
    //            	showTextChat: true,
    //            	showUserList: true,
    //            	maxUsers: 50,
    //            	layout: "Grid",
    //            	showLayoutButton: true,
    //            	scenario: {
    //            		mode: "VideoConference",
    //            		config: {
    //            			role: "Host",
    //          		},
    //          	},
    //          }
  }

  useEffect(() => {
    initializeCall()
  }, [])

  return (
    // <div
    //   id="zego-container"
    //   //    style={{ width: "100vw", height: "100vh" }}

    // />
    <Card>
      <div
        id="zego-container"
        style={{
          //   width: "",
          height: "600px",
          //   position: "absolute",
          //   top: 0,
          //   left: 0,
          //   border: "2px solid #ccc",
          borderRadius: "25px",
        }}
      />
    </Card>
  )
}

export default FullScreenCall
