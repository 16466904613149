// import React, { useEffect } from "react"
// import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
// import { Card } from "reactstrap"
// const VideoCall = ({ roomID, userID, userName, appID, serverSecret }) => {
//   // Function to detect mobile devices
//   function isMobileDevice() {
//     return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
//   }

//   const initializeCall = async () => {
//     const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
//       appID,
//       serverSecret,
//       roomID,
//       userID,
//       userName
//       //   userID.toString(), // Ensure userID is a string
//       // userName.toString() // Ensure userName is a string
//     )
//     // Detect whether the device is mobile
//     const showCameraToggle = isMobileDevice()
//     const zp = ZegoUIKitPrebuilt.create(token)

//     zp.joinRoom({
//       container: document.getElementById("zego-container"),
//       sharedLinks: [
//         {
//           name: "Join Call",
//           // url: `${window.location.origin}${window.location.pathname}?roomID=${roomID}`,
//           url: `${window.location.origin}/VideoCalling?roomID=${roomID}`,
//         },
//       ],
//       scenario: {
//         mode: ZegoUIKitPrebuilt.VideoConference, // For 1:1 calls
//         // mode: ZegoUIKitPrebuilt.OneONoneCall, // For 1:1 calls
//         // mode: ZegoUIKitPrebuilt.VideoResolution_720P, // For 1:1 calls
//       },
//       // showScreenSharingButton: false,
//       // showMyCameraToggleButton: true,

//       // videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P, // Set HD resolution
//       videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P, // Set video resolution to 1080P
//       turnOnMicrophoneWhenJoining: true, // Turn on the microphone by default
//       turnOnCameraWhenJoining: true, // Turn on the camera by default
//       useFrontFacingCamera: true, // Use the front-facing camera
//       showScreenSharingButton: false, // Optional: enable screen sharing
//       showMyCameraToggleButton: true, // Allow toggling the camera
//       // Detect whether the device is mobile
//       // showMyCameraToggleButton: showCameraToggle, // Show camera toggle only on mobile devices
//       showMyMicrophoneToggleButton: true, // Allow toggling the microphone
//       useFrontFacingCamera: true,
//     })

//     // {
//     //            	turnOnMicrophoneWhenJoining: true,
//     //            	turnOnCameraWhenJoining: true,
//     //            	showMyCameraToggleButton: true,
//     //            	showMyMicrophoneToggleButton: true,
//     //            	showAudioVideoSettingsButton: true,
//     //            	showScreenSharingButton: false,
//     //            	showTextChat: true,
//     //            	showUserList: true,
//     //            	maxUsers: 50,
//     //            	layout: "Grid",
//     //            	showLayoutButton: true,
//     //            	scenario: {
//     //            		mode: "VideoConference",
//     //            		config: {
//     //            			role: "Host",
//     //          		},
//     //          	},
//     //          }
//   }

//   useEffect(() => {
//     initializeCall()
//   }, [])

//   return (
//     // <div
//     //   id="zego-container"
//     //   //    style={{ width: "100vw", height: "100vh" }}

//     // />
//     <Card>
//       <div
//         id="zego-container"
//         style={{
//           // marginTop: "100px",
//           // width: "350px", // Set a reasonable width and height for dragging
//           height: "400px",
//           // border: "1px solid #ccc",
//           // borderRadius: "8px",
//           overflow: "hidden",
//           position: "absolute", // Allow positioning within the parent container
//         }}
//         // style={{
//         //   width: "100vw",
//         //   height: "100vh",
//         //   position: "absolute",
//         //   top: 0,
//         //   left: 0,
//         // }}
//       />
//     </Card>
//   )
// }

// export default VideoCall

// import React, { useEffect, useRef, useState } from "react"
// import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
// import { Card, CardBody, Button } from "reactstrap"

// const VideoCall = ({
//   roomID,
//   userID,
//   userName,
//   appID,
//   serverSecret,
//   onLeave,
// }) => {
//   const containerRef = useRef(null)
//   const [isCallEnded, setIsCallEnded] = useState(false)
//   const zegoInstanceRef = useRef(null)

//   // Function to detect mobile devices
//   const isMobileDevice = () => {
//     return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
//   }

//   const initializeCall = async () => {
//     try {
//       const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
//         appID,
//         serverSecret,
//         roomID,
//         userID,
//         userName
//       )

//       const zp = ZegoUIKitPrebuilt.create(token)
//       zegoInstanceRef.current = zp

//       zp.joinRoom({
//         container: containerRef.current,
//         sharedLinks: [
//           {
//             name: "Join Call",
//             // url: `${window.location.origin}${window.location.pathname}?roomID=${roomID}`,
//             url: `${window.location.origin}/VideoCalling?roomID=${roomID}`,
//           },
//         ],
//         scenario: {
//           mode: ZegoUIKitPrebuilt.VideoConference,
//         },
//         videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P,
//         turnOnMicrophoneWhenJoining: true,
//         turnOnCameraWhenJoining: true,
//         useFrontFacingCamera: true,
//         showScreenSharingButton: false,
//         showMyCameraToggleButton: true,
//         showMyMicrophoneToggleButton: true,
//         onLeaveRoom: () => {
//           setIsCallEnded(true)
//           if (onLeave) onLeave()
//         },
//       })
//     } catch (error) {
//       console.error("Error initializing video call:", error)
//     }
//   }

//   const handleRejoin = () => {
//     // Clear previous instance if exists
//     if (zegoInstanceRef.current) {
//       zegoInstanceRef.current.destroy()
//     }

//     // Reset call ended state and reinitialize
//     setIsCallEnded(false)
//     initializeCall()
//   }

//   useEffect(() => {
//     initializeCall()

//     // Cleanup function
//     return () => {
//       if (zegoInstanceRef.current) {
//         zegoInstanceRef.current.destroy()
//       }
//     }
//   }, [])

//   if (isCallEnded) {
//     return (
//       <Card>
//         <CardBody className="text-center">
//           <h4 className="text-danger mb-3">Call Ended</h4>
//           <Button color="primary" onClick={handleRejoin} className="px-4 py-2">
//             Rejoin Call
//           </Button>
//         </CardBody>
//       </Card>
//     )
//   }

//   return (
//     <div
//       ref={containerRef}
//       style={{
//         width: "100%",
//         height: "400px",
//         position: "relative",
//         overflow: "hidden",
//         borderRadius: "8px",
//       }}
//     />
//   )
// }

// export default VideoCall

// ++++++++++++++++++++++++++++++++++++++++++++

// import React, { useEffect, useRef, useState } from "react"
// import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
// import {
//   Card,
//   CardBody,
//   Button,
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap"
// import {
//   Maximize2,
//   Layout,
//   Grid,
//   Columns,
//   Monitor,
//   Smartphone,
// } from "lucide-react"

// const VideoCall = ({
//   roomID,
//   userID,
//   userName,
//   appID,
//   serverSecret,
//   onLeave,
// }) => {
//   const containerRef = useRef(null)
//   const [isCallEnded, setIsCallEnded] = useState(false)
//   const zegoInstanceRef = useRef(null)
//   const [layoutDropdownOpen, setLayoutDropdownOpen] = useState(false)
//   const [currentLayout, setCurrentLayout] = useState("grid")

//   // Layout modes
//   const layoutModes = {
//     grid: {
//       icon: <Grid size={20} />,
//       name: "Grid",
//       config: {
//         gridColumn: 4,
//         fillMode: "aspectFit",
//       },
//     },
//     sidebar: {
//       icon: <Columns size={20} />,
//       name: "Sidebar",
//       config: {
//         gridColumn: 1,
//         fillMode: "aspectFit",
//       },
//     },
//     speaker: {
//       icon: <Monitor size={20} />,
//       name: "Speaker View",
//       config: {
//         gridColumn: 1,
//         fillMode: "cover",
//       },
//     },
//     mobile: {
//       icon: <Smartphone size={20} />,
//       name: "Mobile",
//       config: {
//         gridColumn: 2,
//         fillMode: "aspectFit",
//       },
//     },
//   }

//   // Open full screen in new tab
//   const openFullScreenCall = () => {
//     const newWindow = window.open(
//       `/fullscreen-call?roomID=${roomID}&userID=${userID}&userName=${userName}`,
//       "_blank",
//       "width=1200,height=800"
//     )

//     if (newWindow) {
//       newWindow.focus()
//     }
//   }

//   const initializeCall = async () => {
//     try {
//       const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
//         appID,
//         serverSecret,
//         roomID,
//         userID,
//         userName
//       )

//       const zp = ZegoUIKitPrebuilt.create(token)
//       zegoInstanceRef.current = zp

//       zp.joinRoom({
//         container: containerRef.current,
//         sharedLinks: [
//           {
//             name: "Join Call",
//             url: `${window.location.origin}/VideoCalling?roomID=${roomID}`,
//           },
//         ],
//         scenario: {
//           mode: ZegoUIKitPrebuilt.VideoConference,
//         },

//         // Initial Layout Configuration
//         ...layoutModes[currentLayout].config,

//         videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P,
//         turnOnMicrophoneWhenJoining: true,
//         turnOnCameraWhenJoining: true,
//         useFrontFacingCamera: true,

//         // Responsive UI Configuration
//         showScreenSharingButton: false,
//         showMyCameraToggleButton: true,
//         showMyMicrophoneToggleButton: true,

//         // Media Configuration
//         mediaOptions: {
//           video: {
//             enable: true,
//             input: "default",
//           },
//           audio: {
//             enable: true,
//             input: "default",
//           },
//         },

//         // Lifecycle Handlers
//         onJoinRoom: () => {
//           console.log("Successfully joined room")
//         },
//         onLeaveRoom: () => {
//           setIsCallEnded(true)
//           if (onLeave) onLeave()
//         },
//         onError: error => {
//           console.error("Zego Error:", error)
//         },
//       })
//     } catch (error) {
//       console.error("Error initializing video call:", error)
//     }
//   }

//   // Change Layout
//   const changeLayout = layout => {
//     setCurrentLayout(layout)

//     // If Zego instance exists, update layout
//     if (zegoInstanceRef.current) {
//       try {
//         // Destroy current instance
//         zegoInstanceRef.current.destroy()

//         // Reinitialize with new layout
//         initializeCall()
//       } catch (error) {
//         console.error("Layout change error:", error)
//       }
//     }
//   }

//   useEffect(() => {
//     if (containerRef.current) {
//       initializeCall()
//     }

//     // Cleanup
//     return () => {
//       if (zegoInstanceRef.current) {
//         zegoInstanceRef.current.destroy()
//       }
//     }
//   }, [])

//   // Responsive Styles
//   const containerStyles = {
//     width: "100%",
//     height: "400px",
//     maxHeight: "70vh",
//     position: "relative",
//     overflow: "hidden",
//     borderRadius: "20px",
//     "@media (max-width: 768px)": {
//       height: "250px",
//     },
//   }

//   return (
//     <Card className="position-relative">
//       {/* Full Screen Button */}
//       <Button
//         color="link"
//         className="position-absolute top-0 end-0 m-2 z-10 mb-5"
//         onClick={openFullScreenCall}
//         title="Open in Full Screen"
//       >
//         <Maximize2 size={24} />
//       </Button>

//       {/* Layout Dropdown */}
//       <Dropdown
//         isOpen={layoutDropdownOpen}
//         toggle={() => setLayoutDropdownOpen(!layoutDropdownOpen)}
//         className="position-absolute top-0 start-0 m-2 z-10  mb-5"
//       >
//         <DropdownToggle color="link" className="p-0">
//           <Layout size={24} />
//         </DropdownToggle>
//         <DropdownMenu>
//           {Object.entries(layoutModes).map(([key, layout]) => (
//             <DropdownItem
//               key={key}
//               onClick={() => changeLayout(key)}
//               active={currentLayout === key}
//               className="d-flex align-items-center"
//             >
//               {layout.icon}
//               <span className="ml-2">{layout.name}</span>
//             </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>

//       {/* Video Container */}
//       <div ref={containerRef} style={containerStyles} />
//     </Card>
//   )
// }

// export default VideoCall

import React, { useEffect, useRef, useState } from "react"
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
import {
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import {
  Maximize2,
  Layout,
  Grid,
  Columns,
  Monitor,
  Smartphone,
} from "lucide-react"

const VideoCall = ({
  roomID,
  userID,
  userName,
  appID,
  serverSecret,
  onLeave,
}) => {
  const containerRef = useRef(null)
  const [isCallEnded, setIsCallEnded] = useState(false)
  const zegoInstanceRef = useRef(null)
  const [layoutDropdownOpen, setLayoutDropdownOpen] = useState(false)
  const [currentLayout, setCurrentLayout] = useState("grid")

  // Layout modes
  const layoutModes = {
    grid: {
      icon: <Grid size={20} />,
      name: "Grid",
      config: {
        gridColumn: 4,
        fillMode: "aspectFit",
      },
    },
    sidebar: {
      icon: <Columns size={20} />,
      name: "Sidebar",
      config: {
        gridColumn: 1,
        fillMode: "aspectFit",
      },
    },
    speaker: {
      icon: <Monitor size={20} />,
      name: "Speaker View",
      config: {
        gridColumn: 1,
        fillMode: "cover",
      },
    },
    mobile: {
      icon: <Smartphone size={20} />,
      name: "Mobile",
      config: {
        gridColumn: 2,
        fillMode: "aspectFit",
      },
    },
  }

  // Open full screen in new tab
  const openFullScreenCall = () => {
    const url = `/FullScreenCall?roomID=${roomID}&userID=${userID}&userName=${userName}`
    window.open(url, "_blank")

    // const newWindow = window.open(
    //   `/FullScreenCall?roomID=${roomID}&userID=${userID}&userName=${userName}`,
    //   "_blank",
    //   "width=1200,height=800"
    // )

    // if (newWindow) {
    //   newWindow.focus()
    // }
  }

  const initializeCall = async () => {
    try {
      const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomID,
        userID,
        userName
      )

      const zp = ZegoUIKitPrebuilt.create(token)
      zegoInstanceRef.current = zp

      // Detect mobile device
      const isMobile = window.innerWidth <= 768

      zp.joinRoom({
        container: containerRef.current,
        sharedLinks: [
          {
            name: "Join Call",
            url: `${window.location.origin}/VideoCalling?roomID=${roomID}`,
          },
        ],
        scenario: {
          mode: ZegoUIKitPrebuilt.VideoConference,
        },

        // Initial Layout Configuration
        // For mobile, explicitly set to grid layout
        ...(isMobile
          ? layoutModes["mobile"].config
          : layoutModes[currentLayout].config),

        videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P,
        turnOnMicrophoneWhenJoining: true,
        turnOnCameraWhenJoining: true,
        useFrontFacingCamera: true,

        // Responsive UI Configuration
        showScreenSharingButton: false,
        showMyCameraToggleButton: true,
        showMyMicrophoneToggleButton: true,

        // Media Configuration
        mediaOptions: {
          video: {
            enable: true,
            input: "default",
          },
          audio: {
            enable: true,
            input: "default",
          },
        },

        // Lifecycle Handlers
        onJoinRoom: () => {
          console.log("Successfully joined room")
        },
        onLeaveRoom: () => {
          setIsCallEnded(true)
          if (onLeave) onLeave()
        },
        onError: error => {
          console.error("Zego Error:", error)
        },
      })
    } catch (error) {
      console.error("Error initializing video call:", error)
    }
  }

  // Change Layout
  const changeLayout = layout => {
    setCurrentLayout(layout)

    // If Zego instance exists, update layout
    if (zegoInstanceRef.current) {
      try {
        // Destroy current instance
        zegoInstanceRef.current.destroy()

        // Reinitialize with new layout
        initializeCall()
      } catch (error) {
        console.error("Layout change error:", error)
      }
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      initializeCall()
    }

    // Cleanup
    return () => {
      if (zegoInstanceRef.current) {
        zegoInstanceRef.current.destroy()
      }
    }
  }, [])

  // Responsive Styles
  const containerStyles = {
    width: "100%",
    height: "400px",
    maxHeight: "70vh",
    position: "relative",
    overflow: "hidden",
    borderRadius: "20px",
    "@media (max-width: 768px)": {
      height: "250px",
    },
  }
  return (
    <Card className="position-relative">
      {/* Full Screen Button */}

      {/* Video Container */}
      <div ref={containerRef} style={containerStyles} />

      <Button
        color="link"
        className="position-absolute top-0 end-0  m-2 z-1 mb-5"
        onClick={openFullScreenCall}
        title="Open in Full Screen"
      >
        <Maximize2 size={18} />
      </Button>

      {/* Layout Dropdown */}
      {/* <Dropdown
        isOpen={layoutDropdownOpen}
        toggle={() => setLayoutDropdownOpen(!layoutDropdownOpen)}
        className="position-absolute top-0 start-0 m-2 z-10  mb-5"
      >
        <DropdownToggle color="link" className="p-0">
          <Layout size={24} />
        </DropdownToggle>
        <DropdownMenu>
          {Object.entries(layoutModes).map(([key, layout]) => (
            <DropdownItem
              key={key}
              onClick={() => changeLayout(key)}
              active={currentLayout === key}
              className="d-flex align-items-center"
            >
              {layout.icon}
              <span className="ml-2">{layout.name}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown> */}
    </Card>
  )
}

export default VideoCall
