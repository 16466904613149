import React, { useContext } from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
// import Login from "../pages/Authentication/Login"
import Login from "../pages/AuthenticationInner/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
// import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import HealthTracker from "pages/PaitentDashBoard"
import PaitentRegistrationProfiling from "pages/PaitentRegistrationProfiling"
import NursingStation from "pages/NursingStation/NursingStation"
import VideoCalling from "../pages/VideoCalling/index"
import SystematicExaminationForm from "pages/VideoCalling/SystematicExaminationForm"
import GeneralExaminationForm from "pages/VideoCalling/GeneralExaminationForm"
import PharmacyStore from "pages/PharmacyStore"
import EPrescriptionView from "pages/VideoCalling/EPrescriptionView"
import SosCall from "pages/NursingStation/SosCall"
import FullScreenCall from "pages/VideoCalling/comp/Zego-cloud/VideoCall/FullScreenCall"

const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/healthtracker", component: <HealthTracker /> },
  {
    path: "/PaitentProfiling",
    component: <PaitentRegistrationProfiling />,
  },

  {
    path: "/NursingStation",
    component: <NursingStation />,
  },

  { path: "/SosCall", component: <SosCall /> },
  { path: "/VideoCalling", component: <VideoCalling /> },
  { path: "/FullScreenCall", component: <FullScreenCall /> },

  { path: "/EPrescriptionView", component: <EPrescriptionView /> },
  {
    path: "/SystematicExaminationForm",
    component: <SystematicExaminationForm />,
  },
  { path: "/GeneralExaminationForm", component: <GeneralExaminationForm /> },
  { path: "/PharmacyStore", component: <PharmacyStore /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  // {
  //   path: "*",
  //   exact: true,
  //   component: (
  //     <Navigate
  //       to={localStorage.getItem("T") == "O" ? "/home/dashboard" : "/dashboard"}
  //     />
  //   ),
  // },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  //  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/Recoverpw", component: <Recoverpw /> },

  // landing page
  { path: "/", component: <Login /> },
]

export { publicRoutes, authProtectedRoutes }

