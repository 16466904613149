// src/components/filter.
import React, { useMemo, useState, useEffect } from "react"
import PropTypes from "prop-types"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { Button, Col, Input, Label, Row, Table } from "reactstrap"
// import UpArrow from "../../assets/images/uparrow.svg"

function ICUSupportTable() {
  const columns = useMemo(
    () => [
      {
        Header: "Reading Date & Time",
        accessor: "readingDateTime",
      },
      {
        Header: "Radiant Warmer",
        accessor: "generalCondition",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => {
          const temp = parseFloat(value)
          let color = "white"
          if (temp >= 98) color = "#90EE90"
          else if (temp >= 93) color = "#FFD580"
          else color = "#FF6347"
          return { backgroundColor: color }
        },
      },
      {
        Header: "Vantilators",
        accessor: "temperature",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => {
          const temp = parseFloat(value)
          let color = "white"
          if (temp >= 98) color = "#90EE90"
          else if (temp >= 93) color = "#FFD580"
          else color = "#FF6347"
          return { backgroundColor: color }
        },
      },
      {
        Header: "Phototherapy Unit",
        accessor: "heartRate",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => ({
          backgroundColor: parseInt(value) < 60 ? "#FF6347" : "white",
        }),
      },
      {
        // Header: "Continuous Positive Airway Pressure Machine",
        Header: "CPAPM",
        accessor: "cpapValue",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => ({
          backgroundColor: parseInt(value) < 60 ? "#FF6347" : "white",
        }),
      },
      {
        Header: "Defibrillator",
        accessor: "defibrillatorValue",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => ({
          backgroundColor: parseInt(value) < 60 ? "#FF6347" : "white",
        }),
      },
      {
        Header: "Defibrillator",
        accessor: "defibrillatorValue2",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => ({
          backgroundColor: parseInt(value) < 60 ? "#FF6347" : "white",
        }),
      },
      {
        Header: "Other Support Machine",
        accessor: "OtherSupportMachine",
        Cell: ({ value }) => `${value}`,
        cellStyle: value => ({
          backgroundColor: parseInt(value) < 60 ? "#FF6347" : "white",
        }),
      },
    ],
    []
  )

  const data = [
    {
      readingDateTime: "2023-12-20 10:30:00",
      generalCondition: "98.5",
      temperature: "97.2",
      heartRate: "72",
      cpapValue: "80",
      defibrillatorValue: "90",
      defibrillatorValue2: "85",
      OtherSupportMachine: "Nill",
    },
    {
      readingDateTime: "2023-12-20 11:00:00",
      generalCondition: "97.8",
      temperature: "95.1",
      heartRate: "68",
      cpapValue: "75",
      defibrillatorValue: "85",
      defibrillatorValue2: "80",
      OtherSupportMachine: "Nill",
    },
    {
      readingDateTime: "2023-12-20 11:30:00",
      generalCondition: "96.9",
      temperature: "93.5",
      heartRate: "75",
      cpapValue: "70",
      defibrillatorValue: "80",
      defibrillatorValue2: "75",
      OtherSupportMachine: "Nill",
    },
    {
      readingDateTime: "2023-12-20 12:00:00",
      generalCondition: "97.2",
      temperature: "96.8",
      heartRate: "78",
      cpapValue: "78",
      defibrillatorValue: "85",
      defibrillatorValue2: "82",
      OtherSupportMachine: "Nill",
    },
    {
      readingDateTime: "2023-12-20 12:30:00",
      generalCondition: "98.1",
      temperature: "97.6",
      heartRate: "80",
      cpapValue: "82",
      defibrillatorValue: "88",
      defibrillatorValue2: "85",
      OtherSupportMachine: "Nill",
    },
    {
      readingDateTime: "2023-12-20 13:00:00",
      generalCondition: "99.0",
      temperature: "98.4",
      heartRate: "82",
      cpapValue: "85",
      defibrillatorValue: "90",
      defibrillatorValue2: "88",
      OtherSupportMachine: "Nill",
    },
  ]

  //meta title
  //   document.title = ""

  return (
    <>
      <div className="container-flui">
        <TableContainer
          columns={columns}
          data={data}
          customPageSize={20}
          // classNameHeader="dark"
          // isNextPrvButtonsEnable={true}
          ispagging={false}
          // isAddOptions={false}
          //   isGlobalFilter={true}
          //   className="custom-header-css"
          // isJobListGlobalFilter={false}
          // isNoData={true}
          // NotFoundData=" No Records Found"
        />
      </div>
    </>
  )
}

ICUSupportTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ICUSupportTable
