import ImageFullView from "components/Common/ImageFullView"
import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import ICUSupportTable from "./ICUSupportTable"

const AdditionalInfo = () => {
  const reports = [
    {
      id: 1,
      name: "Report-1",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    {
      id: 2,
      name: "Report-2",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    // {
    //   id: 3,
    //   name: "Report-3",
    //   image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    // },
    // {
    //   id: 4,
    //   name: "Report-4",
    //   image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    // },
    // {
    //   id: 5,
    //   name: "Report-5",
    //   image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    // },
    // {
    //   id: 6,
    //   name: "Report-6",
    //   image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    // },
  ]
  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    // borderRadius: 25,
    padding: 5,
  }

  return (
    <>
      <Card className="mb-4 mt-2" style={formGroupStyle}>
        <CardBody
        // style={formGroupStyle}
        >
          <CardTitle>Medical Prescription</CardTitle>
          <div
            className="d-flex overflow-auto"
            // style={formGroupStyle}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <style>
              {`
                                 div::-webkit-scrollbar {
                               display: none;
                                             }
                                  `}
            </style>
            {reports.map(report => (
              <ImageFullView
                key={report.id}
                imageSrc={report.image}
                altText={report.name}
                triggerElement={
                  <div
                    className="text-center me-1"
                    style={{ minWidth: "100px" }}
                  >
                    {/* <ReportIcon /> */}
                    <img
                      src={report.image}
                      alt=""
                      height="220px"
                      width="150px"
                    />
                    <div>{report.name}</div>
                  </div>
                }
              />
            ))}
          </div>
        </CardBody>
        <ICUSupportTable />
      </Card>
    </>
  )
}

export default AdditionalInfo
