// src/components/filter.
import React, { useMemo, useState, useEffect } from "react"
import PropTypes from "prop-types"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { Button, Col, Input, Label, Row, Table } from "reactstrap"
// import UpArrow from "../../assets/images/uparrow.svg"

function KeyParametersTable() {
  const columns = useMemo(
    () => [
      {
        Header: "Reading Date & Time",
        accessor: "readingDateTime",
      },
      //   {
      //     Header: "General Condition",
      //     accessor: "generalCondition",
      //     Cell: ({ value }) => value,
      //     cellStyle: value => ({
      //       backgroundColor:
      //         value === "Lethragic"
      //           ? "#ffcccb"
      //           : value === "Alert"
      //           ? "#90EE90"
      //           : "white",
      //     }),
      //   },
      {
        Header: "Temperature(F)",
        accessor: "temperature",
        // Cell: ({ value }) => `${value}°F`,
        Cell: ({ value }) => `${value}`,
        cellStyle: value => {
          const temp = parseFloat(value)
          let color = "white"
          if (temp >= 98) color = "#90EE90"
          else if (temp >= 93) color = "#FFD580"
          else color = "#FF6347"
          return { backgroundColor: color }
        },
      },
      //   {
      //     Header: "Heart Rate(/Min)",
      //     accessor: "heartRate",
      //     Cell: ({ value }) => `${value}`,
      //     cellStyle: value => ({
      //       backgroundColor: parseInt(value) < 60 ? "#FF6347" : "white",
      //     }),
      //   },
      //   {
      //     Header: "Apnea",
      //     accessor: "apnea",
      //     Cell: ({ value }) => value,
      //     cellStyle: value => ({
      //       backgroundColor: value === "Yes" ? "#ffcccb" : "white",
      //     }),
      //   },
      //   {
      //     Header: "Respiratory Rate (RR)",
      //     accessor: "respiratoryRate",
      //     Cell: ({ value }) => `${value}`,
      //     cellStyle: value => {
      //       const rate = parseInt(value)
      //       let color = "white"
      //       if (rate >= 60) color = "#FF6347"
      //       else if (rate >= 40) color = "#FFD580"
      //       else if (rate < 30) color = "#FF6347"
      //       return { backgroundColor: color }
      //     },
      //   },
      //   {
      //     Header: "Blood Pressure (BP)",
      //     accessor: "bloodPressure",
      //     Cell: ({ value }) => value,
      //     cellStyle: value => {
      //       const [systolic, diastolic] = value.split("/").map(Number)
      //       let color = "white"
      //       if (systolic > 140 || diastolic > 90) color = "#FF6347"
      //       else if (systolic < 90 || diastolic < 60) color = "#FFD580"
      //       return { backgroundColor: color }
      //     },
      //   },
      //   {
      //     Header: "Grunting",
      //     accessor: "grunting",
      //     Cell: ({ value }) => value,
      //     cellStyle: value => ({
      //       backgroundColor: value === "Yes" ? "#ffcccb" : "white",
      //     }),
      //   },
      //   {
      //     Header: "Head Circumference",
      //     accessor: "headCircumference",
      //     Cell: ({ value }) => `${value}`,
      //     cellStyle: value => {
      //       const circumference = parseFloat(value)
      //       let color = "white"
      //       if (circumference < 32 || circumference > 38) color = "#FFD580"
      //       return { backgroundColor: color }
      //     },
      //   },
      //   {
      //     Header: "Length",
      //     accessor: "length",
      //     Cell: ({ value }) => `${value} `,
      //     cellStyle: value => {
      //       const length = parseFloat(value)
      //       let color = "white"
      //       if (length < 45 || length > 55) color = "#FFD580"
      //       return { backgroundColor: color }
      //     },
      //   },
      //   {
      //     Header: "Bulging Anterior Fontanel",
      //     accessor: "bulgingAnteriorFontanel",
      //     Cell: ({ value }) => `${value} `,
      //     cellStyle: value => ({
      //       backgroundColor: parseFloat(value) > 0.5 ? "#FFD580" : "white",
      //     }),
      //   },
    ],
    []
  )

  const data = [
    {
      readingDateTime: "21 May 2024 10:20 Am",
      generalCondition: "Lethragic",
      temperature: "93°F",
      heartRate: "67 BPM",
      apnea: "Yes",
      respiratoryRate: "45 BPM", // Replace with the actual value
      bloodPressure: "120/80 mmHg", // Replace with the actual value
      grunting: "15 minutes", // Replace with the actual value
      headCircumference: "35 cm", // Replace with the actual value
      length: "49 cm", // Replace with the actual value
      bulgingAnteriorFontanel: "0.7 cm", // Replace with the actual value
    },
    {
      readingDateTime: "20 May 2024 11:07 Am",
      generalCondition: "Lethragic",
      temperature: "98°F",
      heartRate: "60 BPM",
      apnea: "Yes",
      respiratoryRate: "55 BPM", // Replace with the actual value
      bloodPressure: "130/85 mmHg", // Replace with the actual value
      grunting: "20 minutes", // Replace with the actual value
      headCircumference: "34.5 cm", // Replace with the actual value
      length: "50 cm", // Replace with the actual value
      bulgingAnteriorFontanel: "0.5 cm", // Replace with the actual value
    },
    {
      readingDateTime: "17 May 2024 09:48 PM",
      generalCondition: "Alert",
      temperature: "100°F",
      heartRate: "60 BPM",
      apnea: "Yes",
      respiratoryRate: "55 BPM", // Replace with the actual value
      bloodPressure: "130/85 mmHg", // Replace with the actual value
      grunting: "20 minutes", // Replace with the actual value
      headCircumference: "34.5 cm", // Replace with the actual value
      length: "50 cm", // Replace with the actual value
      bulgingAnteriorFontanel: "0.5 cm", // Replace with the actual value
    },
    {
      readingDateTime: "17 May 2024 09:46 PM",
      generalCondition: "Alert",
      temperature: "100°F",
      heartRate: "50 BPM",
      apnea: "Yes",
      respiratoryRate: "55 BPM", // Replace with the actual value
      bloodPressure: "130/85 mmHg", // Replace with the actual value
      grunting: "20 minutes", // Replace with the actual value
      headCircumference: "34.5 cm", // Replace with the actual value
      length: "50 cm", // Replace with the actual value
      bulgingAnteriorFontanel: "0.5 cm", // Replace with the actual value
    },
    {
      readingDateTime: "20 May 2024 11:07 Am",
      generalCondition: "Lethragic",
      temperature: "98°F",
      heartRate: "60 BPM",
      apnea: "Yes",
      respiratoryRate: "55 BPM", // Replace with the actual value
      bloodPressure: "130/85 mmHg", // Replace with the actual value
      grunting: "20 minutes", // Replace with the actual value
      headCircumference: "34.5 cm", // Replace with the actual value
      length: "50 cm", // Replace with the actual value
      bulgingAnteriorFontanel: "0.5 cm", // Replace with the actual value
    },
  ]
  //meta title
  //   document.title = ""

  return (
    <>
      <div className="container-flui">
        <TableContainer
          columns={columns}
          data={data}
          customPageSize={20}
          // classNameHeader="dark"
          // isNextPrvButtonsEnable={true}
          ispagging={false}
          // isAddOptions={false}
          //   isGlobalFilter={true}
          //   className="custom-header-css"
          // isJobListGlobalFilter={false}
          // isNoData={true}
          // NotFoundData=" No Records Found"
        />
      </div>
    </>
  )
}

KeyParametersTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default KeyParametersTable
