// import React, { useState, useEffect } from "react"
// import VideoCall from "./VideoCall"
// import { initializeZego, loginRoom, listenForCallSignal } from "./ZegoService"
// import { Card, Col } from "reactstrap"

// const Receiver = () => {
//   const [isReceivingCall, setIsReceivingCall] = useState(false)
//   const [acceptCall, setAcceptCall] = useState(false)
//   const [callerInfo, setCallerInfo] = useState(null)

//   const roomID = "room01" // Replace with a valid Room ID
//   const userID = "user2" // Replace with a unique User ID for the receiver
//   const userName = "Receiver" // Replace with the receiver's display name
//   const appID = 1233739532
//   const serverSecret = "bd4fc7c7e896e364e33a6395cb09d6ed"
//   useEffect(() => {
//     const setupZego = async () => {
//       try {
//         const zegoClient = initializeZego()

//         console.log(
//           "Room ID:",
//           roomID,
//           "User ID:",
//           userID,
//           "User Name:",
//           userName
//         )

//         // Login to the room
//         await loginRoom(zegoClient, roomID, userID, userName)

//         // Listen for incoming call signals
//         listenForCallSignal(zegoClient, userID, callerData => {
//           console.log("Incoming call from:", callerData)
//           setCallerInfo(callerData)
//           setIsReceivingCall(true)
//         })
//       } catch (error) {
//         console.error("Error setting up Zego for Receiver:", error.message)
//       }
//     }

//     setupZego()
//   }, [])

//   const handleAcceptCall = () => {
//     setAcceptCall(true)
//     setIsReceivingCall(false)
//     console.log("Call accepted.")
//   }

//   return (
//     <div>
//       <Col>
//         <Card>
//           {!acceptCall ? (
//             <div style={{ textAlign: "center", marginTop: "10px" }}>
//               {isReceivingCall ? (
//                 <>
//                   <h3>
//                     Incoming Call from {callerInfo?.userName || "Unknown"}
//                   </h3>
//                   <button
//                     onClick={handleAcceptCall}
//                     style={{
//                       padding: "10px 20px",
//                       backgroundColor: "green",
//                       color: "white",
//                       border: "none",
//                       borderRadius: "5px",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Accept Call
//                   </button>
//                 </>
//               ) : (
//                 <h3>Waiting for a Call...</h3>
//               )}
//             </div>
//           ) : (
//             <VideoCall
//               roomID={roomID}
//               userID={userID}
//               userName={userName}
//               appID={appID}
//               serverSecret={serverSecret}
//             />
//           )}
//         </Card>
//       </Col>
//     </div>
//   )
// }

// export default Receiver

// import React, { useState, useEffect } from "react"
// import VideoCall from "./VideoCall"
// import { Card, CardBody, Col } from "reactstrap"
// import { v4 as uuidv4 } from "uuid"

// const Receiver = () => {
//   const [isReceivingCall, setIsReceivingCall] = useState(false)
//   const [acceptCall, setAcceptCall] = useState(false)
//   const [callerVideoStarted, setCallerVideoStarted] = useState(false)
//   // const [audio] = useState(new Audio("/sos.mp3")) // Ringtone file
//   const [audio] = useState(new Audio("")) // Ringtone file

//   const roomID = "room01" // Room ID
//   // const userID = "user2" // Unique User ID
//   // const userName = "Receiver" // Receiver's name

//   const userID = uuidv4() // Unique User ID
//   const userName = generateUniqueIdentifier("Receiver") // "receiver_bfc800c0-0b8a-4e33-a9dc-9a39f7ac3dda"

//   const appID = 1233739532 // ZEGOCLOUD appID
//   const serverSecret = "bd4fc7c7e896e364e33a6395cb09d6ed" // Your server secret

//   function generateUniqueIdentifier(userName) {
//     // Convert the user name to lowercase and remove spaces
//     const cleanedUserName = userName.toLowerCase().replace(/\s/g, "")

//     // Generate a random UUID
//     const uuid = uuidv4()

//     // Combine the user name and UUID
//     const uniqueIdentifier = `${cleanedUserName}_${uuid}`

//     return uniqueIdentifier
//   }

//   useEffect(() => {
//     // Simulate receiving a call after 3 seconds
//     const callTimeout = setTimeout(() => {
//       setIsReceivingCall(true)
//       // Simulate the caller starting the video after 2 seconds
//       setTimeout(() => {
//         setCallerVideoStarted(true)
//       }, 2000)
//     }, 3000)

//     return () => {
//       clearTimeout(callTimeout)
//       audio.pause()
//       audio.currentTime = 0 // Reset audio
//     }
//   }, [audio])

//   const handleAcceptCall = () => {
//     setAcceptCall(true)
//     setIsReceivingCall(false)
//     setCallerVideoStarted(false) // Stop blinking icon on accept
//     audio.pause()
//     audio.currentTime = 0
//   }

//   useEffect(() => {
//     if (callerVideoStarted && isReceivingCall) {
//       audio.loop = true
//       audio.play().catch(err => console.error("Audio playback error:", err))
//     } else {
//       audio.pause()
//       audio.currentTime = 0
//     }
//   }, [callerVideoStarted, isReceivingCall, audio])

//   return (
//     <div>
//       <div>
//         <h3
//           className=" d-flex flex-column align-items-center mb- mt-4"
//           style={{
//             color: "red",
//             // fontSize: window.innerWidth < 768 ? 18 : 24,
//           }}
//         >
//           Command Center
//         </h3>

//         <Card style={{ width: "100%", minHeight: "400px" }}>
//           {!acceptCall ? (
//             <Card>
//               <CardBody>
//                 <div style={{ textAlign: "center", marginTop: "50px" }}>
//                   {isReceivingCall ? (
//                     <>
//                       <h3>Connect To Command Center</h3>
//                       {callerVideoStarted && (
//                         <div
//                           style={{
//                             margin: "20px 0",
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center",
//                           }}
//                         >
//                           {/* Blinking Alarm Icon */}
//                           <div
//                             style={{
//                               width: "50px",
//                               height: "50px",
//                               borderRadius: "50%",
//                               backgroundColor: "red",
//                               animation: "blink 1s infinite",
//                             }}
//                           ></div>
//                           <style>
//                             {`
//                         @keyframes blink {
//                           0%, 100% { opacity: 1; }
//                           50% { opacity: 0; }
//                         }
//                         `}
//                           </style>
//                         </div>
//                       )}
//                       <button
//                         onClick={handleAcceptCall}
//                         style={{
//                           padding: "10px 20px",
//                           margin: "10px",
//                           backgroundColor: "green",
//                           color: "white",
//                           border: "none",
//                           borderRadius: "5px",
//                           cursor: "pointer",
//                         }}
//                       >
//                         Join
//                       </button>
//                     </>
//                   ) : (
//                     <h3>Connecting to Command Center...</h3>
//                   )}
//                 </div>
//               </CardBody>
//             </Card>
//           ) : (
//             <VideoCall
//               roomID={roomID}
//               userID={userID}
//               userName={userName}
//               appID={appID}
//               serverSecret={serverSecret}
//             />
//           )}
//         </Card>
//       </div>
//     </div>
//   )
// }

// export default Receiver

import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { PhoneIncoming, PhoneOff, AlertCircle, Video } from "lucide-react"
import VideoCall from "./VideoCall"

const Receiver = () => {
  // State Management
  const [isReceivingCall, setIsReceivingCall] = useState(false)
  const [acceptCall, setAcceptCall] = useState(false)
  const [callerVideoStarted, setCallerVideoStarted] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [audio] = useState(new Audio("")) // Placeholder for ringtone

  // Unique Identifiers
  const roomID = "room01"
  const userID = uuidv4()
  const userName = generateUniqueIdentifier("Receiver")

  // Zego Credentials
  const appID = 1233739532
  const serverSecret = "bd4fc7c7e896e364e33a6395cb09d6ed"

  // Generate Unique Identifier Function
  function generateUniqueIdentifier(userName) {
    const cleanedUserName = userName.toLowerCase().replace(/\s/g, "")
    const uuid = uuidv4()
    return `${cleanedUserName}_${uuid}`
  }

  // Simulated Call Incoming Effect
  useEffect(() => {
    const callTimeout = setTimeout(() => {
      setIsReceivingCall(true)
      setTimeout(() => {
        setCallerVideoStarted(true)
      }, 2000)
    }, 3000)

    return () => {
      clearTimeout(callTimeout)
      audio.pause()
      audio.currentTime = 0
    }
  }, [audio])

  // Audio Management Effect
  useEffect(() => {
    if (callerVideoStarted && isReceivingCall) {
      audio.loop = true
      audio.play().catch(err => console.error("Audio playback error:", err))
    } else {
      audio.pause()
      audio.currentTime = 0
    }
  }, [callerVideoStarted, isReceivingCall, audio])

  // Call Handling Functions
  const handleAcceptCall = () => {
    setAcceptCall(true)
    setIsReceivingCall(false)
    setCallerVideoStarted(false)
    audio.pause()
    audio.currentTime = 0
  }

  const handleRejectCall = () => {
    setShowRejectionModal(true)
    audio.pause()
    audio.currentTime = 0
  }

  const closeRejectionModal = () => {
    setShowRejectionModal(false)
    setIsReceivingCall(false)
  }

  // Render Components
  const RingingCallUI = () => (
    <Card>
      <CardBody>
        <div className="text-center mt-5">
          <h3 className="mb-4">Incoming Command Center Call</h3>

          {callerVideoStarted && (
            <div className="d-flex justify-content-center mb-4">
              <AlertCircle color="red" size={60} className="blink-animation" />
            </div>
          )}

          <div className="d-flex justify-content-center gap-3">
            <Button
              color="success"
              onClick={handleAcceptCall}
              className="d-flex align-items-center"
            >
              <PhoneIncoming className="mx-2" /> Join Call
            </Button>
            <Button
              color="danger"
              onClick={handleRejectCall}
              className="d-flex align-items-center"
            >
              <PhoneOff className="mx-2" /> Decline
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  )

  const RejectionModal = () => (
    <Modal isOpen={showRejectionModal} toggle={closeRejectionModal}>
      <ModalHeader toggle={closeRejectionModal}>Call Declined</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <AlertCircle size={60} color="red" className="mb-3" />
          <p>You have declined the incoming Command Center call.</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeRejectionModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <div>
      <Col>
        <h3 className="text-center my-4" style={{ color: "red" }}>
          Command Center
        </h3>

        <Card style={{ width: "100%", minHeight: "400px" }}>
          {!acceptCall ? (
            <>
              {isReceivingCall ? (
                <RingingCallUI />
              ) : (
                <CardBody className="text-center mt-5">
                  <Video size={60} color="gray" className="mb-3" />
                  <h4>Connecting to Command Center...</h4>
                </CardBody>
              )}
              <RejectionModal />
            </>
          ) : (
            <VideoCall
              roomID={roomID}
              userID={userID}
              userName={userName}
              appID={appID}
              serverSecret={serverSecret}
            />
          )}
        </Card>
      </Col>
    </div>
  )
}

export default Receiver

// Global Styles
const styles = `
  .blink-animation {
    animation: blink 1s infinite;
  }
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`
const styleSheet = document.createElement("style")
styleSheet.type = "text/css"
styleSheet.innerText = styles
document.head.appendChild(styleSheet)
