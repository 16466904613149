import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"
import PaitentVitalTable from "./PaitentVitalTable"
import NewbornCaseRecordHeader from "./NewbornCaseRecordHeader"
import PaitentCaseFileCollapseTab from "./PaitentCaseFileCollapseTab"
import KeyVitalsCollapseTab from "./KeyVitalsComp/KeyVitalsCollapseTab"
import BloodPathologyCollapseTab from "./KeyVitalsComp/BloodPathologyCollapseTab"
import RadiologyCollapseTab from "./KeyVitalsComp/RadiologyCollapseTab"
import PaitentProfilingDocs from "./KeyVitalsComp/PaitentProfilingDocs"
import AdditionalInfo from "./KeyVitalsComp/AdditionalInfo"

const LeftBar = () => {
  // document.title = "Video Calling"

  const [activeTab, setactiveTab] = useState("1")
  const [activeTab1, setactiveTab1] = useState("2")
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const tabs = [
    { id: "1", title: "Paitent's Case File" },
    // { id: "2", title: "Paitent's Vital" },
    { id: "2", title: "Key Vitals" },
    { id: "3", title: "Blood Pathology" },
    { id: "4", title: "Radiology/Imaging" },
    { id: "5", title: "Patient's Documents" },
    { id: "6", title: "Additional Information" },
  ]

  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    // padding: 10,
  }

  const iframeRef = useRef(null)
  const openFullScreen = () => {
    if (iframeRef.current) {
      // Request fullscreen for the iframe's container
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen()
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen() // Firefox
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen() // Chrome, Safari, and Opera
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen() // IE/Edge
      }
    }
  }

  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    // Check screen size on mount
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768) // Adjust 768px as needed for your breakpoint
    }
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize)

    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  const [isFullScreen, setIsFullScreen] = useState(true)

  const toggleFullScreen = () => {
    // for mobile devices only for card width
    setIsFullScreen(!isFullScreen)
  }

  const navStyle = {
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    gap: "15px", // Add spacing between NavItems
    // padding: "0 10px", // Optional padding for the container
  }
  const navItemStyle = {
    flex: "0 0 auto",
    // padding: "10px 15px",
    borderRadius: "20px",
    // borderWidth: "11px",
    borderColor: "red",
    cursor: "pointer",
    backgroundColor: "#f8f9fa",
    // backgroundColor: "#fff",
    color: "#343a40",
    // transition: "all 0.3s ease",
    // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    // "&:hover": {
    //   transform: "translateY(-2px)",
    //   boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    //   backgroundColor: "#e9ecef",
    // },
  }

  return (
    <>
      <Container
        fluid={true}
        // style={{ paddingTop: isMobile ? "280px" : "0" }}
      >
        {/* {isMobile && ( // Conditionally render iframe on larger screens only
            <Col md="12" className="d-flex flex-column align-items-center mb-4">
              <>
                <>
                  <Card
                    // style={{ width: "100%" }}
                    style={{
                      // width: "100%",
                      width: isFullScreen ? "100%" : "60%",
                      position: "fixed",
                      // top: 0,
                      top: 0,
                      left: isFullScreen ? "" : 220,
                      zIndex: 1000,
                      paddingTop: "75px",
                    }}
                  >
                    <div
                      ref={iframeRef} // Reference to the div containing the iframe
                      style={{
                        position: "relative",
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        width: "100%",
                      }}
                    >
                      <iframe
                        src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                        allowFullScreen="1"
                        frameBorder="0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>

                    <button
                      type="button"
                      onClick={openFullScreen}
                      className="btn header-item noti-icon"
                      data-toggle="fullscreen"
                    >
                      <i className="bx bx-fullscreen" />
                    </button>
                    <div
                      onClick={toggleFullScreen}
                      className="text-center fs-2 mt-1"
                    >
                      {isFullScreen ? (
                        <>
                          <i className="bx bx-exit-fullscreen" />
                        </>
                      ) : (
                        <>
                          <i className="bx bx-fullscreen" />
                        </>
                      )}
                    </div>
                  </Card>
                </>
              </>
            </Col>
          )} */}
        <>
          <>
            <Card style={formGroupStyle}>
              <CardBody>
                {/* <CardTitle className="h4"> Tabs</CardTitle> */}
                <NewbornCaseRecordHeader />
                <div className="scrollable-tabs">
                  <Nav className="nav-tabs-custom mt-2">
                    {tabs.map(tab => (
                      <NavItem key={tab.id} className="tab-card">
                        <NavLink
                          className={classnames({
                            active: activeTab === tab.id,
                          })}
                          onClick={() => toggle(tab.id)}
                        >
                          <Card
                            className="tab-card-content"
                            style={{
                              // border: "1px solid  #007bff",
                              border: "2px solid  #e5e8eb",
                              borderRadius: "25px",
                              // for tab coutom height
                              minWidth:
                                window.innerWidth >= 768 ? "150px" : "150px",
                              minHeight:
                                window.innerWidth >= 768 ? "60px" : "60px",
                              // maxWidth:
                              //   window.innerWidth >= 768 ? "200px" : "200px",
                              // maxHeight:
                              //   window.innerWidth >= 768 ? "100px" : "100px",
                            }}
                          >
                            <div className="text-center">
                              <CardTitle
                                tag="h6"
                                style={{
                                  textAlign: "center",
                                  // textWrap: "balance",
                                  // textWrap: "pretty",
                                  textWrap: "nowrap",
                                  marginTop: 30,
                                  // marginBottom: 10,
                                  alignItems: "center",
                                  margin: 15,
                                  // fontSize: 20,
                                }}
                              >
                                {tab.title}
                              </CardTitle>
                            </div>
                          </Card>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  {/* <Nav
                    pills
                    // className="navtab-bg nav-justified mb-2"
                    className="nav-tabs-custom mt-2"
                    tabs
                    style={navStyle}
                  >
                    <NavItem className="tab-card">
                      {tabs.map(tab => (
                        <NavItem key={tab.id} className="tab-card">
                          <NavLink
                            style={{
                              cursor: "pointer",
                              // fontSize: 18,
                              fontSize: window.innerWidth < 768 ? 12 : 18,
                              fontFamily: "sans",
                              textAlign: "center",
                              alignItems: "center",

                              padding: "10px 15px",
                              borderRadius: "20px",
                              // backgroundColor: "#f8f9fa",
                              // color: "#343a40",
                            }}
                            className={classnames({
                              active: activeTab === tab.id,
                            })}
                            onClick={() => toggle(tab.id)}
                          >
                            {tab.title}
                          </NavLink>
                        </NavItem>
                      ))}
                    </NavItem>
                  </Nav> */}

                  {/* <Nav
                    pills
                    // className="navtab-bg nav-justified mb-2"
                    className="nav-tabs-custom mt-2"
                    tabs
                    style={navStyle}
                  >
                    <NavItem className="tab-card">
                      <NavLink
                        style={{
                          cursor: "pointer",
                          // fontSize: 18,
                          fontSize: window.innerWidth < 768 ? 12 : 18,
                          fontFamily: "sans",
                          textAlign: "center",
                          alignItems: "center",

                          padding: "10px 15px",
                          borderRadius: "20px",
                          // backgroundColor: "#f8f9fa",
                          // color: "#343a40",
                        }}
                        className={classnames({
                          active: activeTab1 === "1",
                        })}
                        onClick={() => {
                          toggle1("1")
                        }}
                      >
                        Paitent's Case File
                      </NavLink>
                    </NavItem>
                    <NavItem style={navItemStyle}>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          fontSize: window.innerWidth < 768 ? 12 : 18,
                          fontFamily: "sans",
                          textAlign: "center",
                          alignItems: "center",
                          padding: "10px 15px",
                          borderRadius: "20px",
                        }}
                        className={classnames({
                          active: activeTab1 === "2",
                        })}
                        onClick={() => {
                          toggle1("2")
                        }}
                      >
                        Paitent's Vital
                      </NavLink>
                    </NavItem>

                    <NavItem style={navItemStyle}>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          // fontSize: 18,
                          fontSize: window.innerWidth < 768 ? 12 : 18,
                          fontFamily: "sans",
                          textAlign: "center",
                          alignItems: "center",
                          padding: "10px 15px",
                          borderRadius: "20px",
                        }}
                        className={classnames({
                          active: activeTab1 === "3",
                        })}
                        onClick={() => {
                          toggle1("3")
                        }}
                      >
                        Blood Pathology
                      </NavLink>
                    </NavItem>
                    <NavItem style={navItemStyle}>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          // fontSize: 18,
                          fontSize: window.innerWidth < 768 ? 12 : 18,
                          fontFamily: "sans",
                          textAlign: "center",
                          alignItems: "center",
                          padding: "10px 15px",
                          borderRadius: "20px",
                        }}
                        className={classnames({
                          active: activeTab1 === "4",
                        })}
                        onClick={() => {
                          toggle1("4")
                        }}
                      >
                        Radiology/Imaging
                      </NavLink>
                    </NavItem>
                    <NavItem style={navItemStyle}>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          // fontSize: 18,
                          fontSize: window.innerWidth < 768 ? 12 : 18,
                          fontFamily: "sans",
                          textAlign: "center",
                          alignItems: "center",
                          padding: "10px 15px",
                          borderRadius: "20px",
                        }}
                        className={classnames({
                          active: activeTab1 === "5",
                        })}
                        onClick={() => {
                          toggle1("5")
                        }}
                      >
                        Patient's Documents
                      </NavLink>
                    </NavItem>

                    <NavItem style={navItemStyle}>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          // fontSize: 18,
                          fontSize: window.innerWidth < 768 ? 12 : 18,
                          fontFamily: "sans",
                          textAlign: "center",
                          alignItems: "center",
                          padding: "10px 15px",
                          borderRadius: "20px",
                        }}
                        className={classnames({
                          active: activeTab1 === "6",
                        })}
                        onClick={() => {
                          toggle1("6")
                        }}
                      >
                        Additional Information
                      </NavLink>
                    </NavItem>
                  </Nav> */}
                </div>
                <TabContent activeTab={activeTab} className="p-0 text-muted">
                  <TabPane tabId="1">
                    <Row>
                      <PaitentCaseFileCollapseTab />
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      {/* <PaitentVitalTable /> */}
                      <KeyVitalsCollapseTab />
                    </Row>
                  </TabPane>

                  <TabPane tabId="3">
                    <Row>
                      {/* <NewbornCaseRecordHeader /> */}
                      <BloodPathologyCollapseTab />
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <RadiologyCollapseTab />
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row>
                      <PaitentProfilingDocs />
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row>
                      <AdditionalInfo />
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </>
        </>
      </Container>
    </>
  )
}

export default LeftBar
